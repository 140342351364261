import { getNextPage } from "./getNextPage";

// Function to get a random item from an array
function getRandomItem(array) {
  const randomIndex = Math.floor(Math.random() * array.length);
  return array[randomIndex];
}

// Function to update lives icons
function updateLives(incorrectAnswerCount) {
  const livesContainer = document.getElementById("quiz-lives");

  if (livesContainer) {
    const childDivs = livesContainer.querySelectorAll("div");

    // Remove the classes from all child divs
    childDivs.forEach((div) => {
      div.classList.remove("opacity-25", "bg-black");
    });

    // Add classes based on incorrectAnswerCount
    if (incorrectAnswerCount > 0 && incorrectAnswerCount <= 5) {
      for (let i = 0; i < incorrectAnswerCount; i++) {
        const divToUpdate = childDivs[childDivs.length - 1 - i];
        divToUpdate.classList.add("opacity-20", "bg-black/25");
        divToUpdate.classList.remove("bg-yellow-400");
      }
    }
  }
}

document.addEventListener("DOMContentLoaded", function () {
  // Get page elements
  const article = document.getElementById("quiz-article");
  const yesButton = document.getElementById("quiz-yes-button");
  const noButton = document.getElementById("quiz-no-button");
  const heading = document.getElementById("quiz-heading");
  const paragraph = document.getElementById("quiz-paragraph");
  const buttonContainer = document.getElementById("quiz-yes-no-buttons");
  const nextButtonContainer = document.getElementById("quiz-next");
  const nextButton = document.getElementById("quiz-next-button");
  const shareButton = document.getElementById("quiz-share-button");
  const shareCopyLinkContainer = document.getElementById("quiz-share-link-container");
  const shareCopyLink = document.getElementById("quiz-share-copy-link");
  const shareCopyLinkText = document.getElementById("quiz-share-copy-link-text");
  const shareCopyLinkClose = document.getElementById("quiz-share-copy-link-close");
  const image = document.getElementById("quiz-image");
  const score = document.getElementById("quiz-score");
  const total = document.getElementById("quiz-total");
  const questioner = document.getElementById("quiz-questioner");
  const attribution = document.getElementById("quiz-image-attribution");

  // Arrays of face images
  const facesThinking = ["pope-thinking.webp", "pope-thinking-2.webp"];

  const facesCorrect = [
    "pope-happy.webp",
    "pope-happy-2.webp",
    "pope-happy-3.webp",
  ];

  const facesIncorrect = [
    "pope-angry.webp",
    "pope-angry-2.webp",
    "pope-confused.webp",
  ];

  // Arrays of phrases
  const phrasesCorrect = ["✅ Right!"];

  const phrasesIncorrect = ["❌ Wrong!", "❌ Rigid!"];

  if (questioner) {
    questioner.style.backgroundImage = `url('../images/${getRandomItem(
      facesThinking
    )}')`;
  }

  // Adding click event listeners to the buttons
  if (yesButton !== null) {
    yesButton.addEventListener("click", () => checkAnswer(true));
    noButton.addEventListener("click", () => checkAnswer(false));
  }

  // If we're on the home page, set the start button to 0
  var quizStartButton = document.getElementById("quiz-start-button");
  if (quizStartButton !== null) {
    getNextPage()
      .then((nextPage) => {
        quizStartButton.setAttribute("href", `../${nextPage}`);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // Initialize counts from sessionStorage or set them to zero if not present
  let scriptLoadCount = sessionStorage.getItem("scriptLoadCount") || 0;
  let correctAnswerCount = sessionStorage.getItem("correctAnswerCount") || 0;
  let incorrectAnswerCount =
    sessionStorage.getItem("incorrectAnswerCount") || 0;

  updateLives(incorrectAnswerCount);

  if (document.getElementById("quiz-score") !== null) {
    // Update the score text with the correntAnswerCount
    score.textContent = correctAnswerCount;

    // Update the total text with the scriptLoadCount
    total.textContent = scriptLoadCount;
  }

  function checkAnswer(isYes) {
    // Get the true/false response from data-bool attribute
    const yesOrNoDataAttr = article.getAttribute("data-bool");
    // Get location name from data-loc attribute
    const locationAttr = article.getAttribute("data-loc");

    questioner.classList.add("rotate-center");

    setTimeout(function () {
      if (yesOrNoDataAttr) {
        // Hide the yes no button container
        buttonContainer.classList.add("hidden");

        // remove rotating animation
        questioner.classList.remove("rotate-center");

        // Update texts
        if (isYes.toString() === String(yesOrNoDataAttr)) {
          correctAnswerCount++;
          sessionStorage.setItem("correctAnswerCount", correctAnswerCount);
          heading.textContent = getRandomItem(phrasesCorrect);
          questioner.style.backgroundImage = `url('../images/${getRandomItem(
            facesCorrect
          )}')`;
          image.classList.add("bounce-top");
        } else {
          incorrectAnswerCount++;
          sessionStorage.setItem("incorrectAnswerCount", incorrectAnswerCount);
          heading.textContent = getRandomItem(phrasesIncorrect);
          questioner.style.backgroundImage = `url('../images/${getRandomItem(
            facesIncorrect
          )}')`;
          image.classList.add("shake-horizontal");
        }

        // Update the href attribute of the next button with the random number
        if (incorrectAnswerCount < 5) {
          // Get next number in array of pages stored in session storage and generated by getNextPage.js
          getNextPage()
            .then((nextPage) => {
              nextButton.setAttribute("href", `../${nextPage}`);
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          nextButton.setAttribute("href", `../end`);
        }

        // updateLives icon function
        updateLives(incorrectAnswerCount);

        // Open phone's share facility or the hidden copylink container
        shareButton.addEventListener("click", (event) => {
          event.preventDefault();
          if (navigator.share) {
            navigator
              .share({
                title: "Is it Catholic?",
                url: document.location.href,
              })
              .then(() => {
                console.log("Thanks for sharing!");
              })
              .catch(console.error);
          } else {
            shareCopyLinkContainer.classList.remove("hidden");
          }
        });

        // Hande copyLink button
        shareCopyLink.addEventListener("click", (event) => {
          event.preventDefault();
          navigator.clipboard.writeText(document.location.href);
          shareCopyLinkText.innerText = "Link Copied";
          setTimeout(function () {
            shareCopyLinkText.innerText = "Copy link";
          }, 3000);
        });

        // Hide copylink container on 'Close'
        shareCopyLinkClose.addEventListener("click", (event) => {
          event.preventDefault();
          shareCopyLinkContainer.classList.add("hidden");
        });

        // Update paragraph text with name and location of image
        paragraph.textContent = `It's: ${locationAttr}`;

        // If attribution text exists, insert it after the paragraph text
        if (attribution) {
          attribution.classList.remove('hidden');
        }

        // Increase the script load count
        scriptLoadCount++;
        // Store the updated count in sessionStorage
        sessionStorage.setItem("scriptLoadCount", scriptLoadCount);
      }
    }, 2000);

    setTimeout(function () {
      // Show the next button container
      nextButtonContainer.classList.remove("hidden");

      // Update the total with the scriptLoadCount
      total.textContent = scriptLoadCount;

      // Update the count with the correctAnswerCount
      score.textContent = correctAnswerCount;
    }, 3000);
  }
});
