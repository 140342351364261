export async function getNextPage() {
  // Get the current URL
  const currentUrl = window.location.href;
  const baseURL = new URL(currentUrl).origin;

  // Extract the last part of the URL
  const urlParts = currentUrl.replace(/\/+$/, "").trim().split("/");
  const lastUrlPart = urlParts[urlParts.length - 1];

  // Fetch the XML data from baseURL/index.xml
  const xmlFeedUrl = `${baseURL}/index.xml`;
  const response = await fetch(xmlFeedUrl);
  const xmlText = await response.text();

  // Parse the XML data
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xmlText, "text/xml");

  // Count the number of <item> elements in the XML
  const numberOfPages = xmlDoc.getElementsByTagName("item").length;

  console.log("no of pages: " + numberOfPages);

  // Check if the session storage variable 'pageOrder' exists
  if (!sessionStorage.getItem("pageOrder")) {
    let arrayOfPages = [];

    // Create an array of numbers from 1 to numberOfPages
    const allNumbers = [];
    for (let i = 1; i <= numberOfPages; i++) {
      allNumbers.push(i);
    }

    // Shuffle the array
    for (let i = numberOfPages - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [allNumbers[i], allNumbers[j]] = [allNumbers[j], allNumbers[i]];
    }

    // Now arrayOfPages contains the randomized array
    // Add the shuffled array twice to arrayOfPages in case the user lands on/starts
    // from the last item in the array
    arrayOfPages.push(...allNumbers, ...allNumbers);

    // Store the shuffled array as JSON in sessionStorage
    sessionStorage.setItem("pageOrder", JSON.stringify(arrayOfPages));
  }

  // Retrieve the shuffled array from sessionStorage and parse it
  let arrayOfPages = JSON.parse(sessionStorage.getItem("pageOrder"));

  // Check where lastUrlPart is in the array
  const arrayIndex = arrayOfPages.indexOf(parseInt(lastUrlPart));

  // If the user lands on this page (so not been playing the game up to this page)
  // remove all the items in the array before the current index, so it resets to 0
  // and the game begins from this point
  if (sessionStorage.getItem("scriptLoadCount") === null) {
    arrayOfPages.splice(0, arrayIndex);
  }

  // get next page from arrayOfPages
  if (arrayIndex !== -1) {
    if (arrayIndex < numberOfPages - 1) {
      return arrayOfPages[arrayIndex + 1];
    } else {
      return "end";
    }
  } else {
    return arrayOfPages[0];
  }
}
