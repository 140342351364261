// Check if an element with id "final-score" exists
var finalScoreElement = document.getElementById("final-score");

if (finalScoreElement !== null) {
  // get score values
  let scriptLoadCount = sessionStorage.getItem("scriptLoadCount") || 0;
  let correctAnswerCount = sessionStorage.getItem("correctAnswerCount") || 0;

  // Calculate the decimal value
  var decimalValue = correctAnswerCount / scriptLoadCount;

  // Convert the decimal to a percentage
  var percentageValue = decimalValue * 100;

  // add a face based on percentage
  var face = document.getElementById("final-score-face");
  var sentence = document.getElementById("final-score-sentence");

  if (percentageValue < 26) {
    face.style.backgroundImage = `url('../images/pope-angry.webp')`;
    sentence.textContent = '"Must try harder!"';
  } else if (percentageValue < 51) {
    face.style.backgroundImage = `url('../images/pope-bored.webp')`;
    sentence.textContent = '"Not bad, but not great."';
  } else if (percentageValue < 76) {
    face.style.backgroundImage = `url('../images/pope-happyish.webp')`;
    sentence.textContent = '"Quite good."';
  } else {
    face.style.backgroundImage = `url('../images/pope-happy-2.webp')`;
    sentence.textContent = '"Very impressive!"';
  }

  if (scriptLoadCount === 0 && correctAnswerCount === 0) {
    face.style.backgroundImage = `url('../images/pope-confused.webp')`;
    sentence.textContent = '"Something went horribly wrong!"';
  }

  // Update the total with the scriptLoadCount
  const total = document.getElementById("quiz-total");
  total.textContent = scriptLoadCount;

  // Update the count with the correctAnswerCount
  const score = document.getElementById("quiz-score");
  score.textContent = correctAnswerCount;

  // Clear all items when play again button clicked
  const playAgainButton = document.getElementById("final-score-play-again");

  // Add a click event listener to the button
  playAgainButton.addEventListener("click", function() {
    // Clear localStorage
    localStorage.clear();

    // Clear sessionStorage
    sessionStorage.clear();
  });
}