var sentences = [
      "I think therefore I am confused",
      "Even your guardian angel is shrugging right now",
      "The catechism can't help you with this one",
      "God moves in mysterious ways for sure",
      "Thomas Aquinas can't even summa this up",
      "This one has Saint Anthony lost"
];

function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

var shuffledSentences = shuffle(sentences);
var currentIndex = 0;

function displayNextSentence() {
  if (currentIndex < shuffledSentences.length) {
    var paragraph = shuffledSentences[currentIndex];
    currentIndex++;
    var quizParagraph = document.getElementById("quiz-paragraph");
    if (quizParagraph) {
      quizParagraph.innerHTML = paragraph + '';
    };
  }
}

// Call this function to display the first random paragraph
displayNextSentence();
